<template>
  <div class="box">
        <div>
          <div style="margin-top: 20px; padding-bottom: 15px">
            <div v-for="item in sfcList" :key="item.id">
              <div class="list-item">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div>
                    <div style="font-size: 16px; font-weight: 600">
                     {{ item.real_name }} / {{item.region}} {{item.homes}} <span :style="'background:red;'" style="font-size:12px;color:#fff;padding:1px 5px;border-radius: 3px;">待审核</span>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    margin-top: 15px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #f2f2f2;
                  "
                >
                  <div style="display: flex; font-size: 12px">
                    <div>
                      提交日期：{{item.created_at}}
                    </div>
                  </div>
                  
                  <div style="display: flex; font-size: 12px; margin-top: 5px">
                    <div>
                      车牌号：{{item.car_no}}
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; margin-top: 5px">
                    <div>
                      手机号：{{item.mobile}}
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; margin-top: 5px">
                    <div>
                      身份证号：{{item.id_card}}
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; margin-top: 5px">
                    <div>
                      驾驶证/行驶证：
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; margin-top: 5px">
                    <img  alt="" v-for="ite in getImgList(item.jszpic)" :key="ite" :src="ite" style="width:50px;height:50px;margin-right: 10px;;" @click="showPic(ite)">
                    <img  alt="" v-for="ite in getImgList(item.xszpic)" :key="ite" :src="ite" style="width:50px;height:50px;margin-right: 10px;;" @click="showPic(ite)">
                  </div>
                </div>
                <div
                  style="
                    width: 100%;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 10px; ;
                  "
                >
                <div class="cklx" @click="showSh(item)">审核</div>
                </div>
              </div>
            </div>
        </div>
        </div>
        <empty :msg="'暂无待审核'" :show="sfcList.length==0 ? true : false"></empty>
    <loading :show="isShowLoading"></loading>
    <van-popup v-model="showTishi" position="bottom" round>
            <div style="width: 100%;">
              <div style="text-align:center;margin:15px 0;font-size:16px;">审核</div>
              <textarea placeholder="请输入审核意见（非必填）" class="texta" v-model="shyj"></textarea>
              <div class="btn-box">
                <div class="cklx1" @click="sureShbtg">不通过</div>
                <div class="cklx1" @click="sureShtg">通过</div>
              </div>
            </div>
        </van-popup>
  </div>
</template>
<script>
import {  Dialog, Toast, ImagePreview, Popup } from "vant";
import Empty from "./comm/empty.vue";
import Loading from "./comm/loading.vue";
import { passenger } from "@/api";
import dayjs from 'dayjs'
import Config from "@/config";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
export default {
  components: {
    Empty: Empty,
    Loading: Loading,
    VanPopup: Popup,
  },
  data() {
    return {
      showTishi: false,
      activeId: "",
      shyj: "",
      active: 0,
      opacitys: 0,
      opacitys1: 0,
      items: [
        { text: "上城区"},
        { text: "下城区"},
        { text: "江干区"},
        { text: "拱墅区"},
        { text: "西湖区"},
        { text: "滨江区"},
        { text: "萧山区"},
        { text: "余杭区"},
        { text: "临平区"},
        { text: "富阳区"},
        { text: "临安区"},
      ],
      isShowLoading: true,
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: "全部区域", value: 0 },
        { text: "西湖区", value: 1 },
        { text: "滨江区", value: 2 },
      ],
      sfcList: [],
      myInfoType: null,
      myInfo: null,
      toDay: "",
      weatherInfo: null
    };
  },

  mounted() {
    document.title = "车主审核";
    this.myInfoType = JSON.parse(localStorage.getItem("user")).types
    this.myInfo = JSON.parse(localStorage.getItem("user"))
    this.getPassengerTripList();

  },
  methods: {
    showPic(e) {
      ImagePreview([e],0);
    },
    getImgList(e) {
      let rtList = [];
      if(e!=null) {
        let imgs = e.split(",");
          for (let i in imgs) {
              rtList.push(Config.resource.base + imgs[i]);
          }
      }
      return rtList;
      },
    showSh(e) {
      this.myInfo = e
      this.showTishi = true
    },
    sureShbtg() {
      Dialog.confirm({
        title: '提醒',
        message:
          '确定审核不通过吗？',
      })
        .then(() => {
          let params = {
            id: this.myInfo.id,
            shyj: this.shyj
          }
          passenger.sureCarShbtg(params).then(res => {
            if(res.data.code == 200) {
              Toast.success('审核成功')
              this.showTishi = false
              this.getPassengerTripList();
            }
          })
        })
        .catch(() => {
          // on cancel
        });
    },
    sureShtg() {
      Dialog.confirm({
        title: '提醒',
        message:
          '确定审核通过吗？',
      })
        .then(() => {
          let params = {
            id: this.myInfo.id,
            shyj: this.shyj
          }
          passenger.sureCarShtg(params).then(res => {
            if(res.data.code == 200) {
              Toast.success('审核成功')
              this.showTishi = false
              this.getPassengerTripList();
            }
          })
        })
        .catch(() => {
          // on cancel
        });
    },
    formatDate(e) {
      // dayjs.extend(isSameOrAfter)
      let nowDate = dayjs().format('YYYY-MM-DD')
      return dayjs(nowDate).isAfter(dayjs(e, 'day'))
    },
    formatCarNo(e) {
      const nBegin = e.substring(0,2)
      const nEnd = e.substring(e.length - 2, e.length)
      return nBegin + '***' + nEnd
    },
    goTo() {
      if(this.myInfoType == 1) {
        this.$router.push({
            name: 'ckfb'
          })
      } else if(this.myInfoType == 2) {
        this.$router.push({
            name: 'czfb'
          })
      }
    },
    goMy() {
      this.$router.push({
            name: 'my'
          })
    },
    searchXc() {
      this.getPassengerTripList();
    },
    showTrip(e) {
      this.$router.push({
        name: "trip-info",
        query: {
          id: e.id,
        },
      });
    },
    editTrip(e) {
      if(e.types == '1') {
        this.$router.push({
          name: "ckfb",
          query: {
            id: e.id,
            isAdd: 'EDIT'
          },
        });
      } else {
        this.$router.push({
          name: "czfb",
          query: {
            id: e.id,
            isAdd: 'EDIT'
          },
        });
      }
    },
    cencleTrip(e) {
        Dialog.confirm({
        title: '温馨提示',
        confirmButtonText: '确定',
        confirmButtonColor: '#00B5A0',
        message: e.status == 1 ? '确定取消行程吗？': '确定重新发布行程吗？',
        })
        .then(() => {
          if(e.status == 1) {
            let params = {
              id: e.id
            }
            passenger.cencleTrip(params).then(res => {
              if(res.data.code == 200) {
                Toast.success('取消成功')
                this.getPassengerTripList();
              }
            })
          } else {
            let params = {
              id: e.id
            }
            passenger.againTrip(params).then(res => {
              if(res.data.code == 200) {
                Toast.success('发布成功')
                this.getPassengerTripList();
              }
            })
          }
        })
        .catch(() => {
            // on cancel
        });
    },
    onConfirm() {
      this.$refs.item.toggle();
    },
    time2(data) {
      if (data) {
        if (data == this.dayjs().format("YYYY-MM-DD")) {
          return "今天";
        } else if (
          this.dayjs(data) > this.dayjs() &&
          this.dayjs(data) < this.dayjs().add(1, "day")
        ) {
          return "明天";
        } else {
          return this.dayjs(data).format("MM月DD日");
        }
      }
    },
    getPassengerTripList() {
      this.isShowLoading = true;
      let params = {
        page: 1,
        pageSize: 15,
        searchkey: '',
      };
      passenger.getAuthCarList(params).then((res) => {
        this.sfcList = res.data.data;
        this.isShowLoading = false;
      });
    },
    // getPassengerTripDistrictList() {
    //   this.isShowLoading = true;
    //   let params = {};
    //   passenger.getPassengerTripDistrictList(params).then((res) => {
    //     for(let i in res.data.data) {
    //       for(let j in this.items) {
    //         if(res.data.data[i].endDistrict.substring(6,9) == this.items[j].text) {
    //           this.items[j].badge = res.data.data[i].count
    //         }
    //       }
    //     }
    //     this.isShowLoading = false;
    //   });
    // },
  },
};
</script>
<style scoped>
.btn-box{width: 90%;margin-left:5%; display: flex;align-items: center;justify-content: space-between;}
.texta {width: 90%;margin-left:5%;font-size: 16px;height: 120px;padding: 10px;border: 1px solid #f2f2f2;border-radius: 10px;resize: none;}
/deep/ .van-tree-select__nav {
  flex: 0.5;
}
/deep/ .van-sidebar-item--select::before {
  background-color: #42c6ff;
}
.top-box {width: 90%;margin-left: 5%;display: flex;align-items: center;justify-content: space-between;padding-top: 21px;z-index: 2;}
.top-box div p {font-size: 16px;color: #FAFAFA;line-height: 0;}
.riqi {display: flex;align-items: center;}
.riqi img {margin-right: 5px;}
.riqi span {font-size: 13px;color: #FAFAFA;}
.head-sty {width: 44px;height: 44px;border-radius: 50%;}
.list-item {
  width: 92%;
  margin-left: 4%;
  background: #fff;
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
  border-radius: 8px;
  padding: 15px 15px 10px 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.cklx {
  border: 1px solid #2d83e8;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #2d83e8;
}
.cklx1 {
  width: 48%;
  height: 45px;
  color:#fff;
  background: #2d83e8;
  /* border: 1px solid #2d83e8; */
  /* padding: 5px 10px; */
  border-radius: 20px;
  font-size: 18px;
  line-height: 45px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  /* color: #2d83e8; */
}
.bar-box {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.show-more {
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #2d83e8;
  margin-bottom: 10px;
  cursor: pointer;
}
.bar-item {
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #fff;
  justify-content: center;
}
.bar-item img {
  width: 20px;
  margin-right: 5px;
}
</style>
